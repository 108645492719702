import { types } from 'mobx-state-tree';
import Auth, { AuthType, PasswordValid } from './auth';
import UI, { UIType } from './ui';
import User, { UserType } from './user';
import AuthCookie from '@utils/authCookie';

const isTest = process.env.NODE_ENV === 'test';

export interface IGlobalStoreTest {
  auth: Partial<AuthType>;
  ui: Partial<UIType>;
  user: Partial<UserType>;
}

export interface IGlobalStore {
  auth: AuthType;
  ui: UIType;
  user: UserType;
}

export const GlobalStore = types.model({
  auth: Auth,
  ui: UI,
  user: User,
});

const store: IGlobalStore = GlobalStore.create({
  auth: Auth.create({
    passwordValid: PasswordValid.create({}),
    token: isTest ? '12345' : AuthCookie.getCookie(),
    deviceId: isTest ? '12345' : AuthCookie.getDeviceIdCookie(),
    isAuthenticated: !!AuthCookie.getCookie(),
    preverificationCheckError: null,
    emailBackendValidationError: null,
    isCacaoOnboarded: null,
  }),
  ui: UI.create({}),
  user: User.create({}),
});

export default store;
