import { Instance, getRoot, getSnapshot, applySnapshot, flow, types, cast } from 'mobx-state-tree';
import { v4 as uuidv4 } from 'uuid';
import debug from 'debug';
import axios from 'axios';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import CacaoOnboardedCookie from '@yaydoo/react-mobx-stores/lib/http/cookies/CacaoOnboardedCookie';

import { History } from '../types/history';
import { sendFBEvent } from '../utils/thirdparty';

import endpoints from '@configs/endpoints';
import OnboardingTypeNewUserCookie from '@utils/onboardingTypeNewUserCookie';
import { isEmailValid, isPasswordValid } from '@utils/validators';
import AuthCookie from '@utils/authCookie';
import routes from '@constants/routes';
import {
  deviceIdExpMins as expMins,
  validOnboardingTypesRegister,
  paymentLinksOnboardingVersionName,
  initialOnboardingStates,
} from '@configs/variables';
import generateDeviceId from '@utils/generateDeviceId';
import getUrlParam from '@utils/getUrlParam';
import { TermsConditionsVersionName, validRegisterTypes, VerificationStatus } from './enums';

const initialPassword = isPasswordValid('');
const SUSPEND_KEY = 'suspendida';
const REF_TYPE_INVITED_USER = 'REF_TYPE_INVITED_USER';

type VerificationStatusStrings = keyof typeof VerificationStatus;

export interface IFinancialInstrument {
  debitAccount: boolean;
}

export interface ILoginResponse {
  token: string;
  businesses?: { status: VerificationStatusStrings }[];
  user: {
    userId?: string;
    phone: {
      countryCode?: string;
      fullPhoneNumber?: string;
    };
    refUserType?: string;
  };
  securityAuthProfile: {
    ivHash: string;
    newSecurityProfileHash: string;
    originalSalt: string;
    version?: string;
  };
}

export const PasswordValid = types.model({
  ...initialPassword,
});

export const collaboratorUser = types.model({
  email: types.string,
  firstName: types.string,
  middleName: types.string,
  lastName: types.string,
  role: types.string,
  businessId: types.string,
});

const log = debug('store:Auth:log');
const error = debug('store:Auth:error');

const Auth = types
  .model({
    fingerprint: '',
    deviceId: '',
    email: '',
    pin: '',
    emailValid: true,
    passwordValid: PasswordValid,
    emailError: '',
    emailBackendValidationError: types.union(types.boolean, types.null),
    preverificationCheckError: types.union(types.boolean, types.null),
    password: '',
    verificationToken: '',
    isAuthenticated: false,
    registerSubmitted: false,
    submitting: false,
    token: '',
    sutToken: false,
    resetCode: '',
    twoFactorAuthEnabled: false,
    errorMsg: '',
    onboardingVersionName: '',
    redirectUri: types.maybeNull(types.string),
    hasApprovedAccount: false,
    isCacaoOnboarded: types.union(types.boolean, types.null),
    isSutLogin: false,
    termsAndConditionsCheck: false,
    misuseCheck: false,
    isValidCollaboratorCode: false,
    validateCollaboratorCodeError: false,
    validateCollaboratorCodeErrorCode: '',
    collaboratorDataUser: types.maybeNull(collaboratorUser),
    createCollaboratorAccountError: false,
    createCollaboratorAccountErrorCode: '',
    isCreatingCollaboratorAccount: false,
    clientIp: '',
    createdCollaboratorToken: '',
    phone: '',
    phoneError: '',
    phoneValid: false,
    hasCollaboratorPhone: true,
    updateCollaboratorPhoneSuccess: false,
    updateCollaboratorPhoneError: false,
    phonePin: '',
    verifyCollaboratorPhonePinSuccess: false,
    verifyCollaboratorPhonePinError: false,
    isCollaboratorOnboard: types.maybeNull(types.boolean),
    termsConditionsVersionName: types.maybeNull(types.string),
    sendVerificationCodeSuccess: false,
    sendVerificationCodeError: false,
    isValidatingEmail: types.maybeNull(types.boolean),
  })
  .views((self) => ({
    get isEmailSet() {
      return self.email.trim().length > 0;
    },
    get isPhoneSet() {
      return self.phone.replace(/ /g, '').length === 10;
    },
    get duplicatedEmailError() {
      return self.emailBackendValidationError && self.emailError === 'DUPLICATE';
    },
    get isPasswordValid() {
      return (
        self.passwordValid.hasNumber &&
        self.passwordValid.hasCasing &&
        self.passwordValid.validLength &&
        self.passwordValid.hasSpecialCharacter
      );
    },
    get pinCanSubmit() {
      return self.pin.length === 6;
    },
    get termsAndConditionsAccepted() {
      const { ui } = getRoot(self);
      return ui.partner
        ? self.termsAndConditionsCheck
        : self.termsAndConditionsCheck && self.misuseCheck;
    },
    get canSubmitRegister() {
      return !(
        self.emailError !== 'DUPLICATE' &&
        self.emailValid &&
        this.isEmailSet &&
        self.emailBackendValidationError === false &&
        !self.submitting &&
        this.termsAndConditionsAccepted &&
        !self.isValidatingEmail
      );
    },
  }))
  .actions((self) => {
    let initialState = {};
    return {
      afterCreate: () => {
        initialState = getSnapshot(self);
      },
      resetStore: () => {
        const email = self.email;
        applySnapshot(self, initialState);
        self.email = email;
        self.isAuthenticated = false;
      },
    };
  })
  .actions((self) => ({
    updateField: (field: string, value: string | number | boolean) => {
      applySnapshot(self, { ...self, [field]: value });
    },
    logoutReset: () => {
      self.resetStore();
    },
    generateDeviceId: (forceGeneration?: boolean) => {
      const deviceIdCookie = AuthCookie.getDeviceIdCookie();
      if (forceGeneration || !deviceIdCookie) {
        const deviceId = self.email ? generateDeviceId(self.email) : uuidv4();
        self.deviceId = deviceId;
        AuthCookie.setDeviceIdCookie(deviceId);
      } else {
        self.deviceId = deviceIdCookie;
      }
      return self.deviceId;
    },
    loginSuccess: (token: string) => {
      AuthCookie.setCookie(token);
      self.token = token;
      self.password = '';
      self.pin = '';
      self.isAuthenticated = true;
    },
  }))
  .actions((self) => ({
    handleSuccessLogin: (
      loginResponse: ILoginResponse,
      financialInstrument: IFinancialInstrument,
    ) => {
      const { user, securityAuthProfile, token } = loginResponse;
      const securityProfile = {
        ...securityAuthProfile,
        userId: user.userId,
        newSecurityProfileHash: null,
      };

      const isCacaoOnboarded = !!financialInstrument?.debitAccount;
      self.isCacaoOnboarded = isCacaoOnboarded;
      CacaoOnboardedCookie.setCookie(String(isCacaoOnboarded), expMins);

      self.loginSuccess(token);
      AuthCookie.setSecurityCookie(JSON.stringify(securityProfile));

      if (loginResponse.businesses) {
        const [{ status }] = loginResponse.businesses;
        const statusNumber = VerificationStatus[status] as number;

        if ([VerificationStatus.VERIFIED, VerificationStatus.CHA_VERIFIED].includes(statusNumber)) {
          self.hasApprovedAccount = true;
        }
      }
    },
  }))
  .actions((self) => ({
    registerToLogin: () => {
      self.emailValid = true;
      self.emailError = '';
      self.emailBackendValidationError = false;
      self.password = '';
    },
    validatePassword: () => {
      self.passwordValid = isPasswordValid(self.password);
    },
    evaluateIsValidatingEmail: (newEmail: string) => {
      self.isValidatingEmail = self.email !== newEmail;
    },
    validateEmail: () => {
      self.emailError = '';
      if (self.isEmailSet) {
        self.emailValid = isEmailValid(self.email);
      } else {
        self.emailValid = true;
      }
    },
    validatePhone: () => {
      self.phoneError = '';
      if (self.isPhoneSet) {
        self.phoneValid = true;
      } else {
        self.phoneValid = false;
      }
    },
    setEmail: (email: string) => {
      self.email = email;
      self.emailValid = isEmailValid(self.email);
    },
    generateFingerPrint: flow(function* () {
      try {
        self.submitting = true;
        const fp = yield FingerprintJS.load();
        const { visitorId } = yield fp.get();
        self.fingerprint = visitorId;
      } catch (err) {
        //
      } finally {
        self.submitting = false;
      }
    }),
    checkIfEmailExists: flow(function* () {
      self.emailBackendValidationError = null;
      try {
        const { data } = yield axios.get(endpoints.checkIfEmailExists(self.email));

        self.emailBackendValidationError = !!data.errorCode;
      } catch (error) {
        self.emailError = error?.errorCode || '';
        self.emailBackendValidationError = true;
      } finally {
        self.isValidatingEmail = false;
      }
    }),
    preverificationRegister: flow(function* (token: string) {
      self.generateDeviceId(true);
      self.registerSubmitted = false;
      self.submitting = true;
      const referer = getUrlParam('referer');
      const trackingId = getUrlParam('trackingId');
      const onbTypeCookieValue = OnboardingTypeNewUserCookie.getCookie() as validRegisterTypes;
      const { user } = getRoot(self);
      // we set as default payment links onboarding if not match with any valid onboarding type
      const onboardingVersionName =
        validOnboardingTypesRegister[user.onboardingType as validRegisterTypes] ||
        validOnboardingTypesRegister[onbTypeCookieValue] ||
        paymentLinksOnboardingVersionName;

      const partnerProps = user.partnerBusinessId
        ? { partnerBusinessId: user.partnerBusinessId }
        : {};

      try {
        const { data } = yield axios.post(
          endpoints.userPreverification,
          {
            onboardingVersionName,
            email: self.email,
            referralCode: referer,
            trackingMetaDataUrl: window.location.href,
            trackingId: trackingId,
            termsConditionsVersionName:
              self.termsConditionsVersionName || TermsConditionsVersionName.V4,
            businessType: user.businessType,
            ...partnerProps,
          },
          {
            headers: {
              'g-recaptcha-response': token,
            },
          },
        );

        if (data.success) {
          self.registerSubmitted = true;
        }
      } catch (error) {
        //
      } finally {
        self.submitting = false;
      }
    }),
    validateUserPreverification: flow(function* (code: string) {
      self.submitting = true;
      try {
        const { data } = yield axios.patch(endpoints.validateUserPreverification(code), {});

        if (data.success) {
          self.onboardingVersionName = data?.userOnboardingVersionInfo?.onboardingVersionName;
          self.preverificationCheckError = false;
          return data;
        }
      } catch {
        self.preverificationCheckError = true;
      } finally {
        self.submitting = false;
      }
    }),
    createUserAccount: flow(function* () {
      self.submitting = true;
      self.isAuthenticated = false;
      const deviceId = self.generateDeviceId(true);
      try {
        const initialNextScreen =
          initialOnboardingStates[
            self.onboardingVersionName as keyof typeof initialOnboardingStates
          ];
        const body = {
          onboardingVersionName: self.onboardingVersionName,
          deviceId,
          verificationToken: self.verificationToken,
          password: self.password,
          nextScreen: initialNextScreen,
          deviceType: 4,
        };

        const { data } = yield axios.post(endpoints.createAccountWithPassword, body);

        const { loginResponse } = data;
        self.loginSuccess(loginResponse.token);

        // Facebook stuff
        const event = 'CompleteRegistration';

        sendFBEvent(
          event,
          {
            value: 0.1,
            currency: 'USD',
            content_name: 'credit_signup_password',
            status: 'create_account',
          },
          true,
        );
        return data;
      } catch {
        self.isAuthenticated = false;
      } finally {
        self.submitting = false;
      }
    }),
    logout: flow(function* (history?: History) {
      try {
        yield axios.delete(endpoints.logout, {});
        self.logoutReset();
        if (history) {
          AuthCookie.deleteCookie();
          history?.replace(`${routes.LOGIN}${history.location.search}`);
        }
      } catch (error) {
        //
      }
    }),
    login: flow(function* () {
      log('login >>>');
      self.generateDeviceId();
      self.errorMsg = '';

      const { user } = getRoot(self);

      try {
        self.submitting = true;
        log(`emailPhone: ${self.email}`);
        log(`password: ${self.password}`);
        const { data } = yield axios.post(endpoints.login, {
          emailPhone: self.email,
          password: self.password,
          deviceInfo: window.navigator.userAgent,
          deviceId: self.deviceId,
          deviceType: 4,
        });
        const { loginResponse, multiFactorAuthLoginResponse, financialInstrument } = data;

        AuthCookie.setFingerprintCookie(self.fingerprint);
        const twoFactorAuthEnabled = !!multiFactorAuthLoginResponse?.twoFactorAuthEnabled;

        self.twoFactorAuthEnabled = twoFactorAuthEnabled;

        if (loginResponse) {
          if (
            !loginResponse?.user?.phoneVerified &&
            loginResponse?.user?.refUserType === REF_TYPE_INVITED_USER
          ) {
            self.hasCollaboratorPhone = false;
            self.createdCollaboratorToken = loginResponse?.token;
            AuthCookie.setCollaboratorCookie(loginResponse?.token);
          } else {
            self.password = '';
            self.handleSuccessLogin(loginResponse, financialInstrument);
            yield user.getDetails();
          }
        }

        return data;
      } catch (error) {
        const isSuspended = error?.msg?.includes(SUSPEND_KEY);
        if (isSuspended) {
          const errorMsg = error.msg.split('Si olvidaste')[0];
          self.errorMsg = errorMsg;
        }
        throw error;
      } finally {
        self.submitting = false;
      }
    }),
    loginWithMfa: flow(function* () {
      log('loginWithMfa >>>');
      const { user } = getRoot(self);
      try {
        log(`emailPhone: ${self.email}`);
        log(`password: ${self.password}`);
        self.submitting = true;
        self.generateDeviceId();
        const { data } = yield axios.post(endpoints.loginWithMfa, {
          loginRequest: {
            emailPhone: self.email,
            password: self.password,
            deviceInfo: window.navigator.userAgent,
            deviceId: self.deviceId,
            deviceType: 4,
          },
          authCode: self.pin,
        });

        const { loginResponse, financialInstrument } = data;
        if (loginResponse) {
          if (
            !loginResponse?.user?.phoneVerified &&
            loginResponse?.user?.refUserType === REF_TYPE_INVITED_USER
          ) {
            self.hasCollaboratorPhone = false;
            self.createdCollaboratorToken = loginResponse?.token;
            AuthCookie.setCollaboratorCookie(loginResponse?.token);
          } else {
            self.password = '';
            self.handleSuccessLogin(loginResponse, financialInstrument);
            yield user.getDetails();
          }
        }

        return data;
      } finally {
        self.submitting = false;
      }
    }),
    loginWithSut: flow(function* (singleUseToken: string, sessionType: number) {
      const { user } = getRoot(self);
      try {
        self.submitting = true;
        self.generateDeviceId();
        self.isSutLogin = true;
        self.errorMsg = '';
        const { data } = yield axios.post(endpoints.loginWithSut, {
          singleUseToken,
          sessionType: sessionType,
          deviceType: 4,
        });

        const { loginResponse, financialInstrument } = data;
        if (loginResponse) {
          self.sutToken = true;
          self.handleSuccessLogin(loginResponse, financialInstrument);
          yield user.getDetails();
        }
        return data;
      } catch (error) {
        return error;
      } finally {
        self.submitting = false;
      }
    }),
    resendMfaCode: flow(function* () {
      self.errorMsg = '';
      try {
        const deviceId = self.generateDeviceId();
        const { data } = yield axios.post(endpoints.resendMfa, {
          emailPhone: self.email,
          deviceInfo: window.navigator.userAgent,
          deviceType: 4,
          deviceId,
        });
        return data;
      } catch (error) {
        self.errorMsg = error?.msg || '';
        throw error;
      }
    }),
    resetEmail: flow(function* (token: string) {
      const deviceId = generateDeviceId(self.email);
      self.deviceId = deviceId;
      try {
        self.submitting = true;
        const { data } = yield axios.post(
          endpoints.resetEmail,
          {
            email: self.email,
            type: 'SSO',
          },
          {
            headers: {
              'g-recaptcha-response': token,
              'Device-ID': deviceId,
              'Device-Type': 4,
            },
          },
        );

        return data;
      } finally {
        self.isAuthenticated = false;
        self.submitting = false;
      }
    }),
    verifyResetCode: flow(function* () {
      try {
        self.submitting = true;
        const { data } = yield axios.patch(endpoints.verifyResetCode(self.resetCode), {
          code: self.resetCode,
        });
        return data;
      } finally {
        self.submitting = false;
      }
    }),
    resetPassword: flow(function* () {
      try {
        self.submitting = true;
        const { data } = yield axios.patch(endpoints.resetPassword, {
          code: self.resetCode,
          newPassword: self.password,
        });
        self.password = '';
        return data;
      } finally {
        self.submitting = false;
      }
    }),
    verifyTallyResetCode: flow(function* () {
      try {
        self.submitting = true;
        const { data } = yield axios.get(endpoints.verifyTallyResetCode(self.resetCode));
        return data;
      } finally {
        self.submitting = false;
      }
    }),
    resetTallyPassword: flow(function* () {
      try {
        self.submitting = true;
        const { data } = yield axios.post(endpoints.resetTallyPassword, {
          code: self.resetCode,
          newPassword: self.password,
        });
        if (data.success) {
          self.password = '';
        }
        return data;
      } finally {
        self.submitting = false;
      }
    }),
  }))
  .actions((self) => ({
    validateCollaboratorCode: flow(function* (code: string) {
      log('validateCollaboratorCode >>>', code);
      self.submitting = true;
      self.validateCollaboratorCodeError = false;
      self.validateCollaboratorCodeErrorCode = '';
      try {
        const { data } = yield axios.get(endpoints.validateCollaboratorCode(code), {});
        log('data >>>', data);
        if (data.success) {
          self.isValidCollaboratorCode = true;
          self.validateCollaboratorCodeError = false;
          self.collaboratorDataUser = cast(data);
          self.clientIp = data.debug.ip;
          AuthCookie.deleteCookie();
        }
      } catch (e) {
        error('error >>>', e);
        self.validateCollaboratorCodeError = true;
        self.validateCollaboratorCodeErrorCode = e.errorCode;
      } finally {
        self.submitting = false;
      }
    }),
    createCollaboratorAccount: flow(function* (code: string) {
      log('createCollaboratorAccount');
      self.isCreatingCollaboratorAccount = true;
      self.createCollaboratorAccountError = false;
      self.createCollaboratorAccountErrorCode = '';
      try {
        const { data } = yield axios.post(endpoints.createCollaboratorAccount, {
          invitationCode: code,
          password: self.password,
          deviceType: 4,
          deviceInfo: window.navigator.userAgent,
          deviceId: self.generateDeviceId(),
          ip: self.clientIp,
        });
        log('data >>>', data);
        if (data.success) {
          self.createCollaboratorAccountError = false;
          self.createdCollaboratorToken = data.loginResponse.token;
          AuthCookie.setCollaboratorCookie(data.loginResponse.token);
        } else {
          self.createCollaboratorAccountError = true;
        }
      } catch (e) {
        error('error >>>', e);
        self.createCollaboratorAccountError = true;
        self.createCollaboratorAccountErrorCode = e.errorCode;
      } finally {
        self.isCreatingCollaboratorAccount = false;
      }
    }),
    updateCollaboratorPhone: flow(function* () {
      log('updateCollaboratorPhone');
      self.submitting = true;
      // self.updateCollaboratorPhoneSuccess = false;
      self.updateCollaboratorPhoneError = false;

      try {
        const { data } = yield axios.patch(endpoints.updateCollaboratorPhone, {
          phone: self.phone,
          countryCode: 'MX',
        });
        log('data >>>', data);
        if (data.success) {
          self.updateCollaboratorPhoneSuccess = true;
        } else {
          self.updateCollaboratorPhoneError = true;
        }
      } catch (e) {
        error('error >>>', e);
        self.updateCollaboratorPhoneError = true;
      } finally {
        self.submitting = false;
      }
    }),
    verifyCollaboratorPhonePin: flow(function* () {
      log('verifyCollaboratorPhonePin');
      log(self.phonePin);
      self.submitting = true;
      self.verifyCollaboratorPhonePinSuccess = false;
      self.verifyCollaboratorPhonePinError = false;
      try {
        const { data } = yield axios.patch(
          `${endpoints.verifyCollaboratorPhonePin}${self.phonePin}`,
        );
        log('data >>>', data);
        if (data.success) {
          self.hasCollaboratorPhone = true;
          AuthCookie.deleteCollaboratorCookie();
          yield self.login();
        } else {
          self.verifyCollaboratorPhonePinError = true;
        }
      } catch (e) {
        error('error >>>', e);
        self.verifyCollaboratorPhonePinError = true;
      } finally {
        self.submitting = false;
      }
    }),
    sendVerificationCode: flow(function* () {
      log('sendVerificationCode');
      self.submitting = true;
      self.sendVerificationCodeSuccess = false;
      self.sendVerificationCodeError = false;
      try {
        const { data } = yield axios.get(endpoints.sendVerificationCode);
        log('data >>>', data);
        if (data.success) {
          self.sendVerificationCodeSuccess = true;
        } else {
          self.sendVerificationCodeError = true;
        }
      } catch (e) {
        error('error >>>', e);
        self.sendVerificationCodeError = true;
      } finally {
        self.submitting = false;
      }
    }),
    verifyId: flow(function* (id: string) {
      try {
        self.submitting = true;
        const { data } = yield axios.get(endpoints.validateId(id), {});
        return data;
      } finally {
        self.submitting = false;
      }
    }),
  }));

export type AuthType = Instance<typeof Auth>;
export default Auth;
