import loadable from '@loadable/component';
import PageLoader from '@yaydoo/react-components/lib/components/AppLoaders/PageLoader';

const VerifyCollaboratorPhone = loadable(
  () =>
    import(
      /* webpackChunkName: "verifyCollaboratorPhone" */
      './verifyCollaboratorPhone'
    ),
  {
    fallback: <PageLoader type="icon" />,
  },
);

export default VerifyCollaboratorPhone;
