/* eslint-disable max-len */

export default {
  email: 'Ingresa tu correo electrónico',
  emailExists: 'Tu correo ya está registrado',
  title: 'Iniciemos creando tu perfil',
  info: 'Estos serán tus datos de acceso a Oyster. Para comenzar, ingresa tu correo electrónico; recuerda que, en caso de ser persona moral, el registro debe ser realizado por el representante legal.',
  contpaqi: {
    title: 'Te damos la bienvenida a CONTPAQi® Ligas de pago ',
    info: 'Aquí inicia el proceso de registro para que tu negocio pueda cobrar con CONTPAQi® Ligas de pago (powered by Oyster). \n\n Para comenzar, ingresa el correo electrónico que desees registrar.',
  },
  loginInstruction: 'Inicia sesión para continuar con tu proceso',
  existingRequest: '¿Ya cuentas con una solicitud?',
  checkEmail: 'Revisa tu correo electrónico',
  registerSuccess: 'Para continuar, haz clic en el enlace que enviaremos a tu correo ',
  registerSuccessCont: ' para verificar tu información',
  noEmailError: '¿No recibiste el correo?',
  termsAndConditions: 'Términos y Condiciones',
  noticeOfPrivacy: 'Aviso de Privacidad',
  termsAndConditionsCheck: {
    accept: 'Acepto los ',
    complement: ' y el ',
  },
  misuseCheck:
    'Acepto no hacer mal uso de la plataforma, como realizar auto pagos y demás prácticas referidas en los ',
};
