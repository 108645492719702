import { FC } from 'react';
import { Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import OysterYaydooLogo from '@yaydoo/react-components/lib/icons/Logos/OysterYaydoo';

import useStyles from '../styles';
import routes from '@constants/routes';

const Header: FC = () => {
  const css = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  const goToLogin = () => {
    history.push(routes.LOGIN);
  };

  return (
    <Grid container item xs={12} className={css.header}>
      <Grid item className={css.logo}>
        <OysterYaydooLogo defaultColor />
      </Grid>
      <span className={css.headerAction}>
        {t('prerequisiteScreen.loginText')}
        <button
          type="button"
          onClick={goToLogin}
          data-testid="goToLogin"
          className={css.headerActionBtn}
        >
          {t('prerequisiteScreen.login')}
        </button>
      </span>
    </Grid>
  );
};

export default Header;
