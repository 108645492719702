import Cookies from 'js-cookie';
import {
  cookieName,
  cookieCollaboratorName,
  deviceIdExpMins,
  fingerPrintCookieName,
  deviceIdCookie,
  securityCookieName,
  cookieDomain as domain,
} from '@configs/variables';

export const getExpiration = (minutes: number): Date =>
  new Date(new Date().getTime() + 60 * minutes * 1000);

/* Don't set subdomain cookie if current hostname is localhost */
export const isLocalhost = window.location.hostname === 'localhost';
export const cookieConfig = isLocalhost ? {} : { domain };

/* Auth cookie */
export default class AuthCookie {
  static setCookie(value: string): void {
    Cookies.set(cookieName, value, {
      expires: getExpiration(45),
      ...cookieConfig,
    });
  }

  static setCollaboratorCookie(value: string): void {
    Cookies.set(cookieCollaboratorName, value, {
      expires: getExpiration(45),
      ...cookieConfig,
    });
  }

  static getCookie(): string {
    const cookie: string | undefined = Cookies.get(cookieName);
    return cookie || '';
  }

  static getCollaboratorCookie(): string {
    const cookie: string | undefined = Cookies.get(cookieCollaboratorName);
    return cookie || '';
  }

  static deleteCookie(): void {
    Cookies.remove(cookieName, cookieConfig);
  }

  static deleteCollaboratorCookie(): void {
    Cookies.remove(cookieCollaboratorName, cookieConfig);
  }

  static setFingerprintCookie(value: string): void {
    Cookies.set(fingerPrintCookieName, value, {
      expires: getExpiration(45),
      ...cookieConfig,
    });
  }

  static deleteFingerprintCookie(): void {
    Cookies.remove(fingerPrintCookieName, cookieConfig);
  }

  static getFingerprintCookie(): string {
    const cookie: string | undefined = Cookies.get(fingerPrintCookieName);
    return cookie || '';
  }

  static setDeviceIdCookie(value: string): void {
    Cookies.set(deviceIdCookie, value, {
      expires: getExpiration(deviceIdExpMins),
      ...cookieConfig,
    });
  }

  static getDeviceIdCookie(): string {
    const cookie: string | undefined = Cookies.get(deviceIdCookie);
    return cookie || '';
  }

  static deleteDeviceIdCookie(): void {
    Cookies.remove(deviceIdCookie, cookieConfig);
  }

  static setSecurityCookie(value: string): void {
    Cookies.set(securityCookieName, value, {
      expires: getExpiration(deviceIdExpMins),
      ...cookieConfig,
    });
  }

  static getSecurityCookie(): string {
    const cookie: string | undefined = Cookies.get(securityCookieName);
    return cookie || '';
  }

  static deleteSecurityCookie(): void {
    Cookies.remove(securityCookieName, cookieConfig);
  }
}
