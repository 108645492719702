export enum VerificationStatus {
  UNKNOWN_VERIFICATION_STATUS = 0,
  VERIFIED = 3,
  FORMAT_VERIFIED = 4,
  KYC_CHECK_INITIATED = 14,
  VERIFICATION_PENDING = 15,
  EXTERNAL_SERVICE_VERIFIED = 16,
  EXTERNAL_SERVICE_REJECTED = 17,
  CHA_VERIFIED = 18,
  CHA_REJECTED = 19,
  KYC_INFO_CAUTIONED = 25,
  KYC_INFO_SUSPECTED = 26,
}

export enum SessionType {
  UNKNOWN_SESSION_TYPE = 0,
  NEW_SESSION = 1,
  SUB_SESSION = 2,
}

export enum validRegisterTypes {
  PAYMENT_LINKS = 'PAYMENT_LINKS',
  CONTPAQI = 'CONTPAQI',
  POR_COBRAR = 'POR_COBRAR',
}

export enum BusinessType {
  UNKNOWN_BUSINESS_TYPE = 'UNKNOWN_BUSINESS_TYPE',
  PERSONA_FISICA = 'PERSONA_FISICA',
  PERSONA_MORAL = 'PERSONA_MORAL',
}

export enum TermsConditionsVersionName {
  V4 = 'v4',
  '1.0.0' = '1.0.0',
}
