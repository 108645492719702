import loginScreen from './screens/login';
import registerScreen from './screens/register';
import recoveryScreen from './screens/recovery';
import verifyScreen from './screens/verify';
import prerequisiteScreen from './screens/prerequisite';
import verifyCollaboratorScreen from './screens/verifyCollaborator';
import contpaqiLicense from './screens/contpaqiLicense';
import genericLicense from './screens/genericLicense';
import success from './screens/success';
import error from './screens/error';

export default {
  translation: {
    common: {
      userLogin: 'Inicia sesión',
      resetLogin: 'Iniciar sesión',
      retryActionClick: 'Haz clic aquí',
      continue: 'Continuar',
      createProfile: 'Crear perfil',
      logout: 'Guardar y Salir',
      register: 'Regístrate',
      back: 'Atrás',
      yes: 'Sí',
      no: 'No',
      addFile: 'Selecciona o arrastra el archivo',
      complete: 'Completado',
      loading: 'Cargando',
      email: 'Correo electrónico',
      total: 'Total',
      errorTitle: 'Hubo un error',
      errorText: 'Intenta otra vez',
      errorVerification: 'Verifica que la información sea correcta e intenta otra vez',
      agentHelp: 'Un agente te puede ayudar ',
      example: 'Ejemplo',
      success: 'Solicitud recibida',
      done: 'Listo',
    },
    loginScreen,
    registerScreen,
    recoveryScreen,
    verifyScreen,
    prerequisiteScreen,
    verifyCollaboratorScreen,
    contpaqiLicense,
    genericLicense,
    success,
    error,
  },
};
