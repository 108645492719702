import { Theme, makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme: Theme) => ({
  header: {
    padding: '2rem 1rem',
    maxWidth: 1366,
    margin: 'auto',
  },
  logo: {
    '& img': {
      width: '140px',
      height: '30px',
    },
  },
  headerAction: {
    marginLeft: 'auto',
    fontSize: '14px',
    textAlign: 'right',
  },
  headerActionBtn: {
    color: theme.oysterTheme.oyster,
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '20px',
    '&:hover': {
      cursor: 'pointer',
    },
  },

  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 1200,
    margin: 'auto',
  },
  title: {
    fontWeight: 700,
    fontSize: theme.spacing(3),
    textAlign: 'center',
    margin: `${theme.spacing(1.5)}px 0`,
  },
  description: {
    fontWeight: 500,
    fontSize: theme.spacing(2),
    textAlign: 'center',
  },
  cardWrapper: {
    padding: theme.spacing(4),
    justifyContent: 'space-between',
  },
  bannerWrapper: {
    padding: `0 ${theme.spacing(4)}px`,
  },
  cardRoot: {
    height: '90%',
  },
  cardContent: {
    height: '40%',
  },
  cardBtn: {
    background: theme.oysterTheme.pine,
    color: theme.oysterTheme.cotton,
    height: 'auto',
    '&:hover': {
      background: theme.oysterTheme.pine,
    },
  },
  message: {
    whiteSpace: 'pre-line',
  },
  imageContainer: {
    margin: 'auto',
    width: '90%',
  },
  image: {
    objectFit: 'contain',
  },
}));

export default styles;
