/* eslint-disable max-len */

export default {
  errors: {
    invalid: {
      title: 'El link es incorrecto o ha expirado',
    },
    illegal: {
      title: 'El link ya ha sido utilizado',
    },
    forbidden: {
      title: 'El link es incorrecto o ha expirado',
    },
    msg: 'Contacta al administrador del perfil Oyster para que vuelva a enviarte un correo de invitación.',
    invalidCode: {
      title: 'Hubo un error',
      msg: 'El código es incorrecto o ha expirado',
    },
    general: {
      title: 'Hubo un error',
      msg: 'Intenta nuevamente',
    },
    invalidPhone: 'Número de teléfono inválido',
  },
  success: {
    resendCode: {
      title: 'Ok',
      msg: 'Código reenviado',
    },
  },
  createPassword: 'Crear contraseña',
  createPhone: 'Ingresa tu número celular',
  createPhoneInstructions:
    'Te enviaremos un SMS para validar tu número y continuar con el proceso.',
  inputPhonePlaceholder: 'Número celular a 10 dígitos',
  validateSms: 'Para continuar, verifica tu número telefónico',
  validateSmsInstructions:
    'Para continuar, ingresa el código que hemos enviado a tu número <0>+52 {{phoneNumber}}</0> para verificar tu información.',
  resendCode: '¿No recibiste el código?  <0>Haz clic aquí<0>',
  changePhoneNumber: 'Cambiar número',
  changePhoneNumberInstructions: 'Asegúrate de que tu número sea correcto antes de continuar.',
  inputChangePhonePlaceholder: 'Ingresa tu número celular a 10 dígitos',
  change: 'Cambiar',
};
