import axios from 'axios';
import { Instance, flow, types } from 'mobx-state-tree';

import endpoints from '@configs/endpoints';
import Common from '@store/common';
import { validOnboardingVersions, validOnboardingTypesRegister } from '@configs/variables';
import { BusinessType, validRegisterTypes } from './enums';

const User = types
  .compose(
    Common,
    types.model({
      isFetched: false,
      onboardingCompleted: false,
      onboardingType: types.maybeNull(types.string),
      businessType: BusinessType.UNKNOWN_BUSINESS_TYPE,
      partnerBusinessId: '',
    }),
  )
  .views(() => ({}))
  .actions((self) => ({
    getDetails: flow(function* () {
      try {
        self.submitting = true;
        const { data } = yield axios.get(endpoints.getUser);
        const { onboardingCompleted } = data?.user;
        const { userOnboardingVersionInfo } = data;

        self.isFetched = true;
        const redirectToOnboardingApp = validOnboardingVersions.includes(
          userOnboardingVersionInfo?.onboardingVersionName,
        );

        self.onboardingCompleted =
          onboardingCompleted || data.user.refUserType === 'REF_TYPE_INVITED_USER';

        if (redirectToOnboardingApp) {
          self.onboardingType = Object.keys(validRegisterTypes).find(
            (onboardingKey) =>
              validOnboardingTypesRegister[onboardingKey as validRegisterTypes] ===
              userOnboardingVersionInfo.onboardingVersionName,
          ) as validRegisterTypes;
          return { onboardingType: self.onboardingType };
        }
      } finally {
        self.submitting = false;
      }
    }),
  }));

export type UserType = Instance<typeof User>;
export default User;
