import { useCallback, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';

import { validOnboardingTypesRegister, validOnboardingVersions } from '@configs/variables';
import useCustomThemeContext from '@yaydoo/react-components/lib/hooks/useCustomThemeContext';
import { themePartnerNames } from '@yaydoo/react-components/lib/constants';
import getUrlParam from '@utils/getUrlParam';
import PartnerCookie from '@utils/partnerCookie';

import useStore from './useStore';

export interface UsePartnerData {
  id: string;
  isLicense: boolean;
  partner: string;
  baseUrl: string;
  onboardingLink: string;
  setPartner: (partnerName: themePartnerNames) => void;
  verifyId: (id: string) => Promise<Record<string, string>>;
  partnerByOnboardingVersion: (onboardingVersionName: string) => themePartnerNames;
}

export interface UsePartnerProps {
  initialLoad?: boolean;
}

function usePartner({ initialLoad = false }: UsePartnerProps): UsePartnerData {
  const { ui, auth } = useStore();
  const { setCurrentPartnerTheme } = useCustomThemeContext();
  const isLicense = getUrlParam('license') === 'true';
  const id = getUrlParam('id');
  const { params } = useRouteMatch<{ partner: string }>();
  const { partner: partnerParam } = params;
  const partnerCookie = PartnerCookie.getCookie();
  const partner = partnerParam || partnerCookie;
  const baseUrl = `${window.location.origin}/${partner}`;
  const onboardingLink = `${baseUrl}?id=${id}`;
  const arrayValidPartnerNames = Object.values(themePartnerNames);

  const partnerByOnboardingVersion = (onboardingVersionName: string) => {
    const onboardingVersion =
      validOnboardingVersions.find((name) => onboardingVersionName.includes(name)) || '';

    let partnerName = themePartnerNames.NONE;

    if (onboardingVersion !== validOnboardingTypesRegister.PAYMENT_LINKS) {
      partnerName = onboardingVersion
        .replace(/_V[0-9]/, '')
        .toLocaleLowerCase() as themePartnerNames;
      partnerName = partnerName.replace('_', '') as themePartnerNames;
    }
    return partnerName;
  };

  useEffect(() => {
    if (initialLoad) {
      const partnerCoockieIsValid = arrayValidPartnerNames.includes(
        partnerCookie as themePartnerNames,
      );
      if (partnerCoockieIsValid) {
        setCurrentPartnerTheme(partnerCookie as themePartnerNames);
        ui.updateField('partner', partnerCookie as themePartnerNames);
      } else {
        setCurrentPartnerTheme(themePartnerNames.NONE);
        ui.updateField('partner', themePartnerNames.NONE);
      }
    }
  }, []);

  const setPartner = (partnerName: themePartnerNames): void => {
    PartnerCookie.setCookie(partnerName);
    if (arrayValidPartnerNames.includes(partnerName)) {
      setCurrentPartnerTheme(partnerName);
      ui.updateField('partner', partnerName);
    } else {
      setCurrentPartnerTheme(themePartnerNames.NONE);
      ui.updateField('partner', themePartnerNames.NONE);
    }
  };

  const verifyId = useCallback(async (id: string) => {
    return auth.verifyId(id);
  }, []);

  return {
    id,
    isLicense,
    partner,
    baseUrl,
    onboardingLink,
    setPartner,
    verifyId,
    partnerByOnboardingVersion,
  };
}

export default usePartner;
