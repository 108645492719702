import { validRegisterTypes } from '@store/enums';

const baseUrl: string = process.env.REACT_APP_API_URL || '';

const cookieName: string = process.env.REACT_APP_COOKIE_NAME || '';
const cookieCollaboratorName: string = process.env.REACT_APP_COOKIE_COLLABORATOR_NAME || '';
const cookieDomain: string = process.env.REACT_APP_COOKIE_DOMAIN || '';
const deviceIdCookie: string = process.env.REACT_APP_DEVICEID_COOKIE || '';
const fingerPrintCookieName: string = process.env.REACT_APP_FINGERPRINT_COOKIE || '';
const securityCookieName: string = process.env.REACT_APP_SECURITY_COOKIE || '';
const cacaoOnboardedCookieName: string = process.env.REACT_APP_CACAO_COOKIE || '';

const deviceIdExpMins: number = +(process.env.REACT_APP_DEVICEID_EXP_MINS || 15);
const deviceIdFirstChar: string = process.env.REACT_APP_DEVICE_ID_POSITION_FIRST_CHAR || '0';
const deviceIdSecondChar: string = process.env.REACT_APP_DEVICE_ID_POSITION_SECOND_CHAR || '1';

const hubApp: string = process.env.REACT_APP_HUB_APP || '';
const paymentLinks: string = process.env.REACT_APP_PAYMENTLINKS || '';
const webDashboard: string = process.env.REACT_APP_WEB_DASHBOARD || '';
const onboardingApp: string = process.env.REACT_APP_ONBOARDING || '';

/* Onboarding vars */
// TODO: get this value from BE site, add endpoint to get ONB versions
const paymentLinksOnboardingVersionName = 'PAYMENT_LINKS_V1';
const contpaqiOnboardingVersionName = 'CONTPAQI_V1';
const porCobrarOnboardingVersionName = 'POR_COBRAR_V1';

const validOnboardingTypesRegister = {
  [validRegisterTypes.PAYMENT_LINKS]: paymentLinksOnboardingVersionName,
  [validRegisterTypes.CONTPAQI]: contpaqiOnboardingVersionName,
  [validRegisterTypes.POR_COBRAR]: porCobrarOnboardingVersionName,
};

const initialOnboardingStates = {
  [paymentLinksOnboardingVersionName]: 'PERSONAL_INFORMATION_V1',
  [contpaqiOnboardingVersionName]: 'PERSONAL_INFORMATION_V1',
  [porCobrarOnboardingVersionName]: 'PERSONAL_INFORMATION_V1',
};

const validOnboardingVersions = [
  paymentLinksOnboardingVersionName,
  contpaqiOnboardingVersionName,
  porCobrarOnboardingVersionName,
];

const env: string = process.env.REACT_APP_ENVIRONMENT || 'prod';
const siteKey: string = process.env.REACT_APP_RECAPTCHA_KEY || '';
const fbPixelId: string = process.env.REACT_APP_FB_PIXEL_ID || '';
const gtmId: string = process.env.REACT_APP_GTM_ID || '';
const debug: boolean = process.env.ENV !== 'production';
const sentryDSN: string = process.env.REACT_APP_SENTRY_DNS || '';

export {
  env,
  sentryDSN,
  baseUrl,
  cookieName,
  cookieCollaboratorName,
  cookieDomain,
  deviceIdCookie,
  fingerPrintCookieName,
  securityCookieName,
  cacaoOnboardedCookieName,
  hubApp,
  paymentLinks,
  webDashboard,
  onboardingApp,
  deviceIdFirstChar,
  deviceIdSecondChar,
  deviceIdExpMins,
  siteKey,
  fbPixelId,
  gtmId,
  debug,
  paymentLinksOnboardingVersionName,
  contpaqiOnboardingVersionName,
  validOnboardingTypesRegister,
  validOnboardingVersions,
  initialOnboardingStates,
};
