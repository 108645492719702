export default {
  title: 'Invita a todos tus negocios a {{partner}} Ligas de pago ',
  instructions: {
    title: 'Instrucciones',
    description:
      'Comparte este link con el negocio seleccionado para que inicie su proceso de registro. \n\n' +
      'Recuerda que si el registro es para una Persona Moral, deberá realizarlo el representante legal de la empresa.',
  },
  copyLink: 'Copia y comparte este enlace con la persona que realizará el registro',
  success: '¡Enlace copiado con éxito!',
  copied: 'Tu enlace se copió correctamente',
  stepsTitle: 'Una vez iniciado el proceso de registro:',
  steps:
    '• Te notificaremos si la solicitud es aprobada o rechazada. \n\n' +
    '• Si es aprobado, el negocio podrá usar {{partner}} Ligas de pago ¡de inmediato! \n\n' +
    '• Si es rechazado, el negocio NO podrá hacer uso de {{partner}} Ligas de pago. \n\n' +
    '• Cuando el negocio sea aprobado, recibirá una invitación para iniciar sesión. \n\n' +
    '• También se podrán consultar y dar seguimiento a las ligas de pago desde el dashboard en: <0>{{loginUrl}}</0>',
};
