import { applySnapshot, getRoot, types } from 'mobx-state-tree';
import ObjectPath from 'object-path';
import { IGlobalStore } from '@store/index';

const Common = types
  .model({
    submitting: false,
    errorMsg: '',
  })
  .views((self) => ({
    get globalStore(): IGlobalStore {
      return getRoot(self);
    },
  }))
  .actions((self) => ({
    replace(data: typeof self) {
      applySnapshot(self, data);
    },
    updateField: (field: string, value: string | number | boolean | null) => {
      ObjectPath.set(self, field, value);
      applySnapshot(self, self);
    },
  }));

export default Common;
