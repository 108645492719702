import loadable from '@loadable/component';
import Loader from '@components/loader';

const Contpaqi = loadable(
  () =>
    import(
      /* webpackChunkName: "Contpaqi" */
      './Contpaqi'
    ),
  {
    fallback: <Loader />,
  },
);

export default Contpaqi;
