import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { configure } from 'mobx';
import { BrowserRouter } from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';
import TagManager from 'react-gtm-module';

import { ToastProvider } from '@yaydoo/react-components/lib/components/ToastWrapper/ToastContext';
import { DrawerProvider } from '@yaydoo/react-components/lib/components/DrawerWrapper/DrawerContext';

import store from '@store/index';
import axiosConfig from '@configs/axiosConfig';
import i18nInit from '@translations/i18ninit';
import { StoreContext } from '@hooks/useStore';
import webViewBridge from '@utils/webViewBridge';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import { CustomThemeProvider } from '@yaydoo/react-components/lib/theme/CustomThemeProvider';
import { env, fbPixelId, debug, gtmId, sentryDSN } from '@configs/variables';

import Router from './router';
import reportWebVitals from './reportWebVitals';
import 'mobx-react-lite/batchingForReactDom';
import './index.css';

configure({ enforceActions: 'observed' });

i18nInit();
axiosConfig();
webViewBridge();

Sentry.init({
  dsn: sentryDSN,
  integrations: [new Integrations.BrowserTracing()],
  environment: env,
  tracesSampleRate: env !== 'prod' ? 1.0 : 0.2,
});
ReactPixel.init(fbPixelId, undefined, {
  autoConfig: true,
  debug,
});
TagManager.initialize({ gtmId });

ReactDOM.render(
  <StrictMode>
    <CustomThemeProvider>
      <StoreContext.Provider value={store}>
        <ToastProvider>
          <DrawerProvider>
            <BrowserRouter>
              <Router />
            </BrowserRouter>
          </DrawerProvider>
        </ToastProvider>
      </StoreContext.Provider>
    </CustomThemeProvider>
  </StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
