import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import CacaoOnboardedCookie from '@yaydoo/react-mobx-stores/lib/http/cookies/CacaoOnboardedCookie';

import AuthCookie from '@utils/authCookie';
import GlobalStore from '@store/index';
import routes from '@constants/routes';
import endpoints from './endpoints';
import { baseUrl } from './variables';

const AUTH_ERROR = 'AUTH_ERROR';
const PERMISSION_ERROR = 'PERMISSION_ERROR';

const axiosConfig = (): void => {
  axios.defaults.baseURL = baseUrl;
  axios.interceptors.request.use((config: AxiosRequestConfig) => {
    const token = AuthCookie.getCookie();
    const tokenColl = AuthCookie.getCollaboratorCookie();

    config.headers.Authorization = tokenColl ? tokenColl : token;
    config.headers.Accept = 'application/json';
    config.headers['Content-Type'] = 'application/json';
    config.headers['Accept-Language'] = 'es';
    config.headers['Device-ID'] = AuthCookie.getDeviceIdCookie() || GlobalStore.auth.deviceId;
    config.headers['Device-Type'] = 4;
    return config;
  });

  axios.interceptors.response.use(
    (response: AxiosResponse) => {
      const requestUrl = response?.config?.url || '';
      const { error, errorCode, msg } = response.data;
      const noAuthMsg = msg === 'no authorizado';
      const isNoAuth =
        noAuthMsg ||
        errorCode === PERMISSION_ERROR ||
        errorCode === AUTH_ERROR ||
        response.status === 401;
      const isLoginRequest = [
        endpoints.login,
        endpoints.loginWithMfa,
        endpoints.loginWithSut,
        endpoints.logout,
      ].includes(requestUrl);

      if (isNoAuth || noAuthMsg) {
        const token = AuthCookie.getCookie();
        if (token) AuthCookie.deleteCookie();
        AuthCookie.deleteSecurityCookie();
        CacaoOnboardedCookie.deleteCookie();

        if (!isLoginRequest) {
          /* don't reload for login request */
          const search = window.location.search;
          window.location.href = `${window.location.origin}${routes.LOGIN}${search}`;
          /* Don't want to throw an error, since it is already handled */
          return Promise.resolve({
            ...response,
            errorCode: AUTH_ERROR,
          }) as unknown as AxiosResponse;
        }
      }

      if (error) return Promise.reject(response.data);
      return response;
    },
    (error: AxiosError) => Promise.reject(error),
  );
};

export default axiosConfig;
