import { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { Route, Redirect, Switch, useHistory } from 'react-router-dom';

import routes from '@constants/routes';

import Login from '@pages/login';
import Register from '@pages/register';
import Recovery from '@pages/recovery';
import ResetPassword from '@pages/recovery/resetPassword';
import Verify from '@pages/verify';
import VerifyCollaborator from '@pages/verifyCollaboratorCode';
import VerifyCollaboratorPhone from '@pages/verifyCollaboratorPhone';

import useDebugger from '@hooks/useDebugger';
import useResizeListener from '@hooks/useResizeListener';
import useRouterEffects from '@hooks/useRouterEffects';
import useNewUserOnboardingType from '@hooks/useNewUserOnboardingType';
import PreRequisite from '@pages/PreRequisite';
import Contpaqi from '@pages/Contpaqi';
import usePartner from '@hooks/usePartner';
import GenericPartner from '@pages/GenericPartner';

const Router: FC = () => {
  const history = useHistory();

  useDebugger();
  useResizeListener();
  useRouterEffects();
  useNewUserOnboardingType();
  usePartner({ initialLoad: true });

  return (
    <Switch>
      <Route exact path={routes.LOGIN} component={Login} />
      <Route exact path={routes.REGISTER} component={Register} />
      <Route exact path={routes.RECOVERY} component={Recovery} />
      <Route exact path={routes.PRE_REQUISITE} component={PreRequisite} />
      <Route exact path={routes.RECOVERY_PASSWORD} component={ResetPassword} />
      <Route
        exact
        path={routes.TALLY_PASSWPRD}
        render={(props) => <ResetPassword {...props} isTally />}
      />
      <Route exact path={routes.VERIFY_EMAIL} component={Verify} />
      <Route path={routes.COLLABORATOR_CONFIRMATION_CODE} component={VerifyCollaborator} />
      <Route path={routes.COLLABORATOR_CONFIRMATION_PHONE} component={VerifyCollaboratorPhone} />
      <Route exact path={routes.CONTPAQI} component={Contpaqi} />
      <Route exact path={routes.PARTNER()} component={GenericPartner} />
      <Route exact path={routes.PARTNER_LOGIN()} component={Login} />
      <Route exact path={routes.PARTNER_RECOVERY()} component={Recovery} />
      <Route exact path={routes.PARTNER_RECOVERY_PASSWORD()} component={ResetPassword} />
      <Route exact path={routes.PARTNER_VERIFY_EMAIL()} component={Verify} />
      <Redirect from="*" to={{ ...history.location, pathname: routes.LOGIN }} />
    </Switch>
  );
};

export default observer(Router);
