import loadable from '@loadable/component';
import Loader from '@components/loader';

const Login = loadable(
  () =>
    import(
      /* webpackChunkName: "login" */
      './login'
    ),
  {
    fallback: <Loader />,
  },
);

export default Login;
