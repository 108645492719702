import loadable from '@loadable/component';
import Loader from '@components/loader';

const VerifyEmail = loadable(
  () =>
    import(
      /* webpackChunkName: "verify" */
      './verify'
    ),
  {
    fallback: <Loader />,
  },
);

export default VerifyEmail;
