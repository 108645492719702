import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import useStore from '@hooks/useStore';
import getUrlParam from '@utils/getUrlParam';
import * as Sentry from '@sentry/react';
import CacaoOnboardedCookie from '@yaydoo/react-mobx-stores/lib/http/cookies/CacaoOnboardedCookie';
import routes from '@constants/routes';

import {
  env as enviroment,
  cookieDomain,
  cacaoOnboardedCookieName as cookieName,
  onboardingApp,
  hubApp,
} from '@configs/variables';
import { validRegisterTypes } from '@store/enums';

interface IUseRouterEffects {
  onboardingType: string | null;
  redirectUri: string | null;
}

function useRouterEffects(): IUseRouterEffects {
  const { user, auth } = useStore();
  const history = useHistory();
  const { isFetched, onboardingCompleted, onboardingType } = user;
  const { sutToken, hasApprovedAccount, redirectUri, hasCollaboratorPhone } = auth;

  useEffect(() => {
    if (auth.email && auth.twoFactorAuthEnabled) {
      Sentry.setUser({ email: auth.email });
    }
  }, [auth.email, auth.twoFactorAuthEnabled]);

  useEffect(() => {
    if (!hasCollaboratorPhone) {
      history.push(routes.COLLABORATOR_CONFIRMATION_PHONE);
      return;
    }

    if (isFetched) {
      const approvedUser = hasApprovedAccount && onboardingCompleted;
      const redirectIsOnbApp = !!redirectUri?.includes(onboardingApp);

      if (approvedUser) {
        if (!redirectUri || redirectIsOnbApp) {
          window.location.replace(hubApp);
          return;
        }
        window.location.replace(redirectUri);
        return;
      }
      if (
        Object.values(validRegisterTypes).includes((onboardingType as validRegisterTypes) || '')
      ) {
        window.location.replace(onboardingApp);
        return;
      }

      window.location.replace(hubApp);
    }
  }, [
    sutToken,
    isFetched,
    redirectUri,
    hasApprovedAccount,
    onboardingCompleted,
    onboardingType,
    hasCollaboratorPhone,
  ]);

  useEffect(() => {
    if (auth.token) {
      if (auth.isCollaboratorOnboard === false) {
        if (auth.isSutLogin && !auth.sutToken) {
          return;
        }
      }
      user.getDetails();
    }
  }, [auth.token, auth.isSutLogin, auth.sutToken, auth.isCollaboratorOnboard]);

  useEffect(() => {
    CacaoOnboardedCookie.init({
      enviroment,
      cookieDomain,
      cookieName,
    });
    const uri = getUrlParam('redirect_uri');
    const collaboratorEmail = getUrlParam('email');
    if (uri) auth.updateField('redirectUri', uri);
    if (collaboratorEmail) {
      auth.updateField('isCollaboratorOnboard', true);
      auth.updateField('email', atob(collaboratorEmail));
    } else {
      auth.updateField('isCollaboratorOnboard', false);
    }
  }, []);

  return { onboardingType, redirectUri };
}

export default useRouterEffects;
