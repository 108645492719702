import { useEffect } from 'react';
import useStore from '@hooks/useStore';

function useResizeListener(): void {
  const { auth, ui } = useStore();

  useEffect(() => {
    ui.detectDeviceType();
    auth.generateFingerPrint();
    const handleResize = () => {
      ui.updateField('width', window.innerWidth);
      ui.updateField('height', window.innerHeight);
      if (window.innerWidth <= 640 && !ui.isMobileScreen) {
        ui.updateField('isMobileScreen', true);
      } else if (window.innerWidth > 640 && ui.isMobileScreen) {
        ui.updateField('isMobileScreen', false);
      }
      if (window.innerHeight <= 500) {
        ui.setIsKeyboardPopped(true);
      } else {
        ui.setIsKeyboardPopped(false);
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
}

export default useResizeListener;
