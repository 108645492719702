import { useEffect } from 'react';

import getUrlParam from '@utils/getUrlParam';
import OnboardingTypeNewUserCookie from '@utils/onboardingTypeNewUserCookie';
import { validRegisterTypes } from '@store/enums';

// The difference between mobx store onboardingType, is that it is for an already
// registered user that has an onboarding type set
// The onboarding type from cookie, is from a user that has navigated
// to the auth app, and does not have an account
// It is first passed as a query string, and then we save it in case the user
// reloads the page

function useNewUserOnboardingType(): void {
  // query string value >>>> cookie onb type
  useEffect(() => {
    const arrayValidRegisterTypes = Object.values(validRegisterTypes);

    const cookieOnbType = OnboardingTypeNewUserCookie.getCookie();
    const cookieOnbTypeIsValid = arrayValidRegisterTypes.includes(
      cookieOnbType as validRegisterTypes,
    );
    const paramOnboardingType = getUrlParam('onboarding_type');
    const paramOnbTypeIsValid = arrayValidRegisterTypes.includes(
      paramOnboardingType as validRegisterTypes,
    );

    if (paramOnbTypeIsValid) {
      OnboardingTypeNewUserCookie.setCookie(paramOnboardingType);
      return;
    }

    // cookie onb type is not valid, set PAYMENT_LINKS as default, else, refresh cookie
    OnboardingTypeNewUserCookie.setCookie(
      cookieOnbTypeIsValid ? cookieOnbType : validRegisterTypes.PAYMENT_LINKS,
    );
  }, []);
}

export default useNewUserOnboardingType;
