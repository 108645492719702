import loadable from '@loadable/component';
import Loader from '@components/loader';

const Register = loadable(
  () =>
    import(
      /* webpackChunkName: "register" */
      './register'
    ),
  {
    fallback: <Loader />,
  },
);

export default Register;
