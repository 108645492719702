import loadable from '@loadable/component';
import Loader from '@components/loader';

const Recovery = loadable(
  () =>
    import(
      /* webpackChunkName: "recovery" */
      './recovery'
    ),
  {
    fallback: <Loader />,
  },
);

export default Recovery;
