import { v4 as uuidv4 } from 'uuid';

type TObj = Record<string, unknown>;
type TApply = (t: null, response: string) => void;

interface ICallback {
  success: {
    apply: TApply;
  };
  error: {
    apply: TApply;
  };
}

interface IWebViewBridge {
  send: (obj: TObj, type: string) => void;
}

interface IWebkit {
  messageHandlers: {
    AppleInterface: { postMessage: (msg: string) => void };
  };
}

interface IMsgObj {
  type: string;
  data: TObj;
  msgId?: string;
}

type TCallback = Record<string, ICallback>;

//Declare webViewBridge as global Variable
declare global {
  interface Window {
    webViewBridge: IWebViewBridge;
    ReactNativeWebView: {
      postMessage: (msg: string) => void;
    };
    AndroidInterface: {
      onMessage: (msg: string) => void;
    };
    webkit: IWebkit;
  }
}

export const isNativeView = (): boolean => {
  return !!(window.ReactNativeWebView || window.AndroidInterface || window.webkit);
};

let promiseChain = Promise.resolve();
const callbacks: TCallback = {};
const init = (): void => {
  window.webViewBridge = {
    /**
     * send message to the React-Native WebView, Android Native and iOS Native onMessage handler
     * @param data - data to pass
     * @param type - message code type so RN knows how to handle request
     * @param success - success callback
     * @param error - error callback
     */
    send(data: TObj, type: string, success?: TApply, error?: TApply) {
      const msgObj: IMsgObj = { type, data: data || {} };

      if (success || error) msgObj.msgId = uuidv4();

      const msg = JSON.stringify(msgObj);

      promiseChain = promiseChain
        .then(
          () =>
            new Promise<void>((resolve) => {
              if (msgObj.msgId && success && error) {
                callbacks[msgObj.msgId] = { success, error };
              }

              if (window.ReactNativeWebView) {
                window.ReactNativeWebView.postMessage(msg);
              }
              if (window.AndroidInterface) {
                window.AndroidInterface.onMessage(msg);
              }
              if (window.webkit) {
                window.webkit.messageHandlers.AppleInterface.postMessage(msg);
              }
              resolve();
            }),
        )
        .catch(() => {
          // rnBridge send failed
        });
    },
  };

  window.addEventListener('message', (e) => {
    let message: {
      response: string;
      success?: string;
      msgId: string;
    };
    try {
      message = JSON.parse(e.data);
    } catch (err) {
      return;
    }

    if (message.response && callbacks[message.msgId]) {
      if (message.success) {
        callbacks[message.msgId].success.apply(null, message.response);
      } else {
        callbacks[message.msgId].error.apply(null, message.response);
      }
      delete callbacks[message.msgId];
    }
  });
};

export default init;
