export default {
  BASE: '/',
  LOGIN: '/login',
  REGISTER: '/registro',
  VERIFY_EMAIL: '/verificar/:code',
  RECOVERY: '/recovery',
  RECOVERY_PASSWORD: '/reset/password',
  PRE_REQUISITE: '/pre-requisitos',
  TALLY_PASSWPRD: '/reset/password/new',
  COLLABORATOR_CONFIRMATION_CODE: '/onboarding-collaborator',
  COLLABORATOR_CONFIRMATION_PHONE: '/collaborator-phone',
  CONTPAQI: '/contpaqi',
  PARTNER: ({ partner = ':partner' } = {}): string => `/${partner}`,
  PARTNER_LOGIN: ({ partner = ':partner' } = {}): string => `/${partner}/login`,
  PARTNER_RECOVERY: ({ partner = ':partner' } = {}): string => `/${partner}/recovery`,
  PARTNER_RECOVERY_PASSWORD: ({ partner = ':partner' } = {}): string =>
    `/${partner}/reset/password`,
  PARTNER_VERIFY_EMAIL: ({ partner = ':partner' } = {}): string => `/${partner}/verificar/:code`,
};
