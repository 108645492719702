import { FC } from 'react';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import Go from '@yaydoo/react-components/lib/icons/Arrows/Go';
import Warning from '@yaydoo/react-components/lib/icons/Warning';
import { oysterTheme } from '@yaydoo/react-components/lib/theme';
import Banner from '@yaydoo/react-components/lib/components/Banner';
import InfoCard from '@yaydoo/react-components/lib/components/InfoCard';

import Header from './header';
import useStyles from './styles';
import useStore from '@hooks/useStore';
import { BusinessType } from '@store/enums';

interface CardProps {
  title: string;
  description: string;
  id: string;
  btnLabel: string;
  imgSrc: string;
  businessType: BusinessType;
}

const Card: FC<CardProps> = ({ title, description, id, btnLabel, imgSrc, businessType }) => {
  const css = useStyles();
  const { user } = useStore();

  const handleClick = () => {
    user.updateField('businessType', businessType);
    window.location.replace('https://oyster.io/waiting-list/');
  };

  return (
    <Grid item xs={12} md={6}>
      <InfoCard
        title={title}
        message={description}
        imageSrc={imgSrc}
        classes={{
          message: css.message,
          imageContainer: css.imageContainer,
          image: css.image,
          content: css.cardContent,
          root: css.cardRoot,
        }}
        actionButton={{
          id,
          label: btnLabel,
          className: css.cardBtn,
          onClick: handleClick,
          icon: <Go />,
        }}
      />
    </Grid>
  );
};

const PreRequisite: FC = () => {
  const { t } = useTranslation();
  const css = useStyles();

  return (
    <Grid container data-testid="prerequisite-page">
      <Header />
      <Grid container item xs={12} className={css.content}>
        <Grid item xs={12} className={css.title}>
          {t('prerequisiteScreen.title')}
        </Grid>
        <Grid item xs={12} className={css.description}>
          {t('prerequisiteScreen.description')}
        </Grid>
        <Grid item md={10} sm={12} container className={css.cardWrapper} spacing={3}>
          <Card
            title={t('prerequisiteScreen.pf.title')}
            description={t('prerequisiteScreen.pf.description')}
            id="cl_prerequisites_pf"
            btnLabel={t('prerequisiteScreen.start')}
            imgSrc="/assets/images/prerequisite-pf.svg"
            businessType={BusinessType.PERSONA_FISICA}
          />
          <Card
            title={t('prerequisiteScreen.pm.title')}
            description={t('prerequisiteScreen.pm.description')}
            id="cl_prerequisites_pm"
            btnLabel={t('prerequisiteScreen.start')}
            imgSrc="/assets/images/prerequisite-pm.svg"
            businessType={BusinessType.PERSONA_MORAL}
          />
        </Grid>
        <Grid item md={10} sm={12} className={css.bannerWrapper}>
          <Banner
            title={t('prerequisiteScreen.noBusiness.title')}
            text={t('prerequisiteScreen.noBusiness.description')}
            icon={() => <Warning color={oysterTheme.pineapple} fontSize={42} />}
            buttonText=""
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PreRequisite;
