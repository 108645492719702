/* eslint-disable max-len */

const emailReg =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export interface PasswordValid {
  hasNumber: boolean;
  hasCasing: boolean;
  validLength: boolean;
  hasSpecialCharacter: boolean;
}

export const isEmailValid = (email: string): boolean => {
  return emailReg.test(email?.toLowerCase());
};

export const isPasswordValid = (password: string): PasswordValid => ({
  hasNumber: /(?=(?:\D*\d){3})/.test(password),
  hasCasing: /^(.*?[A-Z]){2,}.*$/.test(password) && /^(.*?[a-z]){2,}.*$/.test(password),
  hasSpecialCharacter: /[!@#$%^&*(),.?":;{}|<>]/.test(password),
  validLength: password.length >= 10,
});
