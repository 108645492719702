import ReactPixel from 'react-facebook-pixel';

const sendFBEvent = (eventName: string, data: unknown, custom = false): void => {
  if (custom) {
    ReactPixel.trackCustom(eventName, data);
  } else {
    ReactPixel.track(eventName, data);
  }
};

export { sendFBEvent };
