/* eslint-disable max-len */

export default {
  title: 'Crea tu contraseña',
  info: 'Esta contraseña te servirá para ingresar a Oyster; asegúrate de guardarla en un lugar seguro',
  contpaqi: {
    info: 'Esta contraseña te servirá para ingresar a la plataforma en donde podrás revisar reportes y dar seguimiento a tus pagos. Asegúrate de proteger tu contraseña en un lugar seguro.',
  },
  validatePass: {
    title: 'Tu contraseña debe tener:',
    validLength: '10 caracteres',
    hasCasing: 'Dos mayúsculas y dos minúsculas',
    hasNumber: 'Tres números',
    hasSpecialCharacter: 'Un carácter especial',
  },
  submit: 'Crear cuenta',
  inputLabel: 'Crea tu contraseña',
  accountCreatedTitle: 'Tu cuenta ya ha sido creada',
  accountCreatedText:
    'El correo ingresado ya tiene una solicitud iniciada, ingresa para continuar con tu proceso',
  emailVerifiedTitle: 'Correo verificado',
  emailVerifiedText: 'Tu correo ha sido verificado correctamente',
};
