export default {
  loginText: ' Si ya iniciaste tu solicitud,',
  login: 'Inicia sesión',
  title: '¡Nos encanta tenerte con nosotros!',
  description: 'Elige tu perfil y evoluciona las finanzas de tu empresa',
  start: '¡Comencemos!',
  pf: {
    title: 'Persona física con actividad empresarial',
    description: `Para registrarte, necesitas:
      • INE
      • Cuenta bancaria a tu nombre`,
  },
  pm: {
    title: 'Persona moral',
    description: `Para registrarte, necesitas:
      •  Ser el representante legal
      • INE
      • Cuenta bancaria a nombre de la empresa`,
  },
  noBusiness: {
    title: 'Para unirte, es necesario tener un negocio activo',
    description:
      'Oyster está enfocado en negocios formalizados. Si no estás registrado en el SAT bajo el régimen de persona física con actividad empresarial o persona moral, tu perfil no será aprobado. Lamentamos los inconvenientes.',
  },
};
