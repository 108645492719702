export default {
  login: '/auth/login/mfa',
  loginWithMfa: '/auth/verify/mfa',
  resendMfa: '/auth/verify/mfa/resend',
  loginWithSut: '/auth/single-use-token',
  resetEmail: '/v2/user/password/reset/request',
  resetPassword: '/user/password/reset',
  verifyResetCode: (code: string): string => `/user/verification/password/${code}`,
  resetTallyPassword: '/user/password/initial',
  verifyTallyResetCode: (code: string): string => `/user/password/validate/request?code=${code}`,
  preverification: (email: string): string => `/user/preverification/create/${email}`,
  userPreverification: '/user/preverification/create',
  checkIfEmailExists: (email: string): string => `/user/verification/email/${email}`,
  validateUserPreverification: (code: string): string => `/user/validate/usercreation/${code}`,
  createAccountWithPassword: '/user/account/create',
  logout: '/auth/logout',
  getUser: '/user',
  getOnboardingState: '/user/onboarding/state',
  validateCollaboratorCode: (code: string): string => `/user/invitation/validate/${code}`,
  createCollaboratorAccount: '/invited/user/account/create',
  updateCollaboratorPhone: '/user/phone',
  verifyCollaboratorPhonePin: '/user/verification/phone/',
  sendVerificationCode: '/user/verification/phone/code',
  validateId: (id: string): string => `/validate/partner/business/${id}`,
};
